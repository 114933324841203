// @mui material components
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDTypography from "components/MDTypography";

import Helpers from "Helpers";
import MDAvatar from "components/MDAvatar";
import copy from "copy-to-clipboard";
import { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { delVideo, fetchVideos, getVideo } from "store/video";
import Swal from "sweetalert";
import { getToast } from "toaster";
import MDBox from "components/MDBox";
import { Checkbox, Tooltip } from "@mui/material";
export default function Data() {
  const { videos } = useSelector((state) => state.videos);
  const { selectedProject } = useSelector((state) => state.projects);
  const { state, ...location } = useLocation();
  const [videoId, setVideoId] = useState("");
  const [pId, setpId] = useState("");
  const [viewMediaId, setViewMedia] = useState("");
  const [loader, setLoader] = useState(true);
  const [selectedVideos, setSelectedVideos] = useState([]); // Define state to store selected video IDs
  const [selectAllPages, setSelectAllPages] = useState(false); // Inside the VideoList component
  const [selectAll, setSelectAll] = useState(false); // select All in Current page
  const { auth } = useSelector((state) => state);
  const { videoFilters } = useSelector((state) => state?.videos);
  const copyToClipboard = async (url, type) => {
    if (url) {
      // Copy with options
      let copied = copy(url, {
        debug: true,
      });
      if (copied) {
        toast.info(`${type} copied successfully!`, getToast(2000));
      }
    }
  };

  const showMedia = async (id) => {
    if (id) {
      // open modal // fetch api to get type of image
      await dispatch(getVideo(id));
      setViewMedia(id);
    } else {
      // something went wrong
      Swal("Error, Media not found!");
    }
  };
  const editVideo = async (id, pId) => {
    if (id) {
      setLoader(true);
      // open edit modal
      await dispatch(getVideo(id));
      setLoader(false);
      setVideoId(id);
      setpId(pId);
    } else {
      // something went wrong
      Swal("Video not found!");
    }
  };
  const deleteVideo = (id) => {
    if (id) {
      Swal({
        title: "Are you sure?",
        text: "You want to delete video!",
        icon: "warning",
        buttons: {
          cancel: "Cancel!",
          ok: {
            text: "Yes, delete it!",
          },
        },
        dangerMode: true,
      }).then(async (confirm) => {
        if (confirm) {
          // call api to delete user
          let res = await dispatch(delVideo(id));
          if (res) {
            Swal("Poof! Video has been deleted!", {
              icon: "success",
            });
            await dispatch(fetchVideos(videoFilters));
          }
        } else {
          Swal("You have cancelled operation!");
        }
      });
    }
  };

  const dispatch = useDispatch();

  useMemo(() => {
    setLoader(true);
    // call api or anything
    const fetch = async () => {
      const filter = {};
      if (state) {
        filter["filter_project_id"] = state;
      }
      await dispatch(fetchVideos(filter)).then((res) => {
        setLoader(false);
      });
      // }
    };
    fetch();
  }, [selectedProject]);

  function areAllValuesInInput1(input1, input2) {
    return input2?.every((value) => input1?.includes(value));
  }

  // Handler function to manage selecte videos
  const handleSelect = (checked, videoId) => {
    if (selectAllPages) {
      // If all pages are selected
      if (!checked) {
        // Add the video ID to the selectedVideos array
        setSelectedVideos([...selectedVideos, videoId]);
      } else {
        // Remove the video ID from the selectedVideos array
        setSelectedVideos(selectedVideos.filter((id) => id !== videoId));
      }
    } else {
      // If not all pages are selected, handle checkbox as usual
      if (checked) {
        // Add the video ID to the selectedVideos array
        setSelectedVideos([...selectedVideos, videoId]);
      } else {
        // Remove the video ID from the selectedVideos array
        setSelectedVideos(selectedVideos.filter((id) => id !== videoId));
      }
    }
  };

  // Handler function to manage selecte all from current page videos
  const handleSelectAll = (isSelected) => {
    const allVideoIds = videos?.data?.map((video) => video.id);
    // Handler function to select or deselect all videos
    if (isSelected) {
      const removeFromCurrent = selectedVideos?.filter(
        (value) => !allVideoIds.includes(value)
      );
      setSelectedVideos(removeFromCurrent);
    } else {
      let uniqueArr = [...new Set([...allVideoIds, ...(selectedVideos ?? [])])];
      setSelectedVideos(uniqueArr);
    }
    setSelectAll(!selectAll);
  };

  let videoListRow = [];
  let rowObj = {};
  for (let video in videos.data) {
    const fileTitle = `Click to view media : ${videos.data[video].filename}`;
    rowObj["checkbox"] = (
      (auth?.user?.id == videos.data[video]?.created_by || auth?.user?.is_lead == "yes") ? <MDTypography display="flex" alignItems="center">
        <Checkbox
          checked={
            selectAllPages
              ? !selectedVideos?.includes(videos?.data[video]?.id)
              : selectedVideos?.includes(videos?.data[video]?.id)
          }
          disabled={selectAllPages}
          onChange={(e) =>
            !selectAllPages &&
            handleSelect(e.target.checked, videos?.data[video]?.id)
          }
        />
      </MDTypography> : <></>
    );
    rowObj["filename"] = (
      <MDBox display="flex" alignItems="center" gap={2}>
        <Tooltip title={videos.data[video].uploaded_by.name} placement="left">
          <MDAvatar
            /**src={image}*/ name={videos.data[video].uploaded_by.name}
            size="sm"
            alt={videos.data[video].uploaded_by.name}
          />
        </Tooltip>
        <MDTypography
          type="button"
          variant="body"
          color="info"
          title={fileTitle}
          onClick={() => {
            showMedia(videos.data[video].id);
          }}
        >
          {Helpers.abbreviateLongText(videos.data[video].filename)}
        </MDTypography>
      </MDBox>
    );
    rowObj["title"] = (
      <MDTypography
        variant="caption"
        type="button"
        onClick={() => {
          copyToClipboard(videos.data[video].title, "Filename");
        }}
        color="text"
        fontWeight="medium"
        title={`Click to copy - "${videos.data[video].title}"`}
      >
        {Helpers.abbreviateLongText(videos.data[video].title)}
      </MDTypography>
    );
    rowObj["created_by"] = (
      <MDTypography variant="caption" color="text" fontWeight="medium">
        {videos.data[video].project.title}
      </MDTypography>
    );
    rowObj["updated_at"] = (
      <MDTypography variant="caption" color="text" fontWeight="medium">
        {Helpers.formatDate(videos.data[video].updated_at, "LLL")}
      </MDTypography>
    );
    rowObj["action"] = (
      <>
        <MDTypography
          variant="body"
          type="button"
          color="dark"
          title="Copy To Clipboard"
          onClick={() => {
            copyToClipboard(videos.data[video].link, "URL");
          }}
        >
          <Icon>copy</Icon>
        </MDTypography>
        &nbsp;
        <MDTypography
          variant="body"
          type="button"
          color="info"
          title="Update Media"
          onClick={() => {
            editVideo(videos.data[video].id, videos.data[video].project_id);
          }}
        >
          <Icon>edit</Icon>
        </MDTypography>
        &nbsp;
        <MDTypography
          title="Remove Media"
          type="button"
          onClick={() => {
            deleteVideo(videos.data[video].id, state);
          }}
          variant="body"
          color="error"
        >
          <Icon>delete</Icon>
        </MDTypography>
      </>
    );
    videoListRow.push(rowObj);
    rowObj = {};
  }
  return {
    columns: [
      {
        Header: (
          <Checkbox
            disabled={selectAllPages && videos?.data?.length === 0}
            checked={
              videos?.data?.length > 0 &&
              (selectAllPages
                ? !selectedVideos?.some((value) =>
                  videos?.data?.map((v) => v?.id)?.includes(value)
                )
                : areAllValuesInInput1(
                  selectedVideos,
                  videos?.data?.map((v) => v?.id)
                ))
            }
            onClick={() =>
              !selectAllPages &&
              handleSelectAll(
                areAllValuesInInput1(
                  selectedVideos,
                  videos?.data?.map((v) => v?.id)
                )
              )
            }
            style={{
              transform: "scale(1.1)",
            }}
          />
        ),
        accessor: "checkbox",
        align: "center",
        width: "7%",
      },
      {
        Header: "Video/Image Name",
        accessor: "filename",
        align: "left",
      },
      { Header: "File Name", accessor: "title", align: "left" },
      { Header: "Project Name", accessor: "created_by", align: "left" },
      { Header: "Last Updated", accessor: "updated_at", align: "center" },
      { Header: "action", accessor: "action", align: "center" },
    ],
    TotalRecords: videos.total,
    rows: videoListRow,
    videoId: videoId,
    pId: pId,
    setVideoId: setVideoId,
    viewMediaId: viewMediaId,
    setViewMedia: setViewMedia,
    loader: loader,
    selectRows: { selectedVideos, setSelectedVideos },
    selectAllPage: { selectAllPages, setSelectAllPages },
  };
}
