import React, { useEffect, useState } from "react";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { DateRangePicker } from "react-date-range";
import { Modal, Button, Stack } from "react-bootstrap";
import MDButton from "components/MDButton";
import Helpers from "Helpers";

// Custom Date Range Picker Component
const MyDateRangePicker = ({ state, setState, onChange }) => {
  // Handler function for when date range selection changes
  const handleOnChange = (ranges) => {
    const { selection } = ranges;
    onChange(selection); // Propagate selection change to parent component
    setState([selection]); // Update internal state with new selection
  };
  return (
    <DateRangePicker
      onChange={handleOnChange}
      showSelectionPreview={true}
      moveRangeOnFirstSelection={false}
      months={2}
      ranges={state} // Current selected date range
      direction="horizontal"
    />
  );
};

// Current selected date range
const DateRangeModal = ({ start_date, end_date, onChange }) => {
  // State for controlling modal visibility and selected date range
  const [showModal, setShowModal] = useState(false);
  const [state, setState] = useState([
    {
      startDate: start_date, // Start date of the date range
      endDate: end_date, // End date of the date range
      key: "selection", // Key identifier for the range
    },
  ]);

  const handleClose = () => setShowModal(false); // Handler function to close the modal
  const handleShow = () => setShowModal(true); // Handler function to open the modal

  // Handler function to reset the selected date range
  function handleReset() {
    onChange({
      start_date: null,
      end_date: null,
    });
    setState([
      {
        startDate: null,
        endDate: null,
        key: "selection",
      },
    ]);
  }

  useEffect(() => {
    setState([
      {
        startDate: start_date,
        endDate: end_date,
        key: "selection",
      },
    ]);
  }, [start_date, end_date]);

  return (
    <>
      <Stack direction="row">
        {/* Button to open the modal */}
        <MDButton
          sx={{ minWidth: 185, minHeight: 44 }}
          variant="outlined"
          color="secondary"
          onClick={handleShow}
        >
          {state?.length > 0
            ? state[0]?.startDate && state[0]?.endDate
              ? Helpers?.formatDate(state[0]?.startDate) +
                " To " +
                Helpers?.formatDate(state[0]?.endDate)
              : "Open Date Range Picker"
            : "Open Date Range Picker"}
        </MDButton>
        {/* Modal for selecting date range */}
        <Modal show={showModal} size="xl" onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Select Date Range</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="d-flex justify-content-center">
              <MyDateRangePicker
                onChange={onChange}
                setState={setState}
                state={state}
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleReset}>
              Reset
            </Button>
            {/* <Button variant="secondary" onClick={handleClose}>
              Close
            </Button> */}
            <Button variant="primary" onClick={handleClose}>
              Okay
            </Button>
          </Modal.Footer>
        </Modal>
      </Stack>
    </>
  );
};

export default DateRangeModal;
